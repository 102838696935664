(() => {
  if (window.customElements.get('pr-badge')) return;

  class PrideraiserBadge extends HTMLElement {
    get color(): string | null {
      return this.getAttribute('color');
    }
    set color(value: string | null) {
      if (value) {
        this.setAttribute('color', value);
      } else {
        this.removeAttribute('color');
      }
    }

    connectedCallback() {}
  }

  window.customElements.define('pr-badge', PrideraiserBadge);
})();
